<template>
  <div>
    <ly-searchList :iptData="iptData" @searchAdd="searchAdd" @resetBtn="resetBtn">
      <template #tail>
        <ly-button @click="newAdd" class="m-l1" type="primary" style="background: #396AFC;border-color:#396AFC" :loading="false">
          <span style="margin-right: 3px;">+</span>新增机构
        </ly-button>
      </template>
    </ly-searchList>
    <div class="m-t1 tableBox">
      <ly-table :bordered="false" :columns="columns" :loading="loading" rowKey="id" @reqGetList="reqGetList" :pagination="pagination" @pageChange="pageChange" ref="tableRef" :data="data">
        <template #add="{ record }">
          <a @click="edit(record)">编辑</a>
          <span class="m-l2 color-R cur-p" @click="delAdd(record)">删除</span>
        </template>
      </ly-table>
    </div>
    <ly-modal v-model:visible="visible" :title="title" :footer="false">
      <ly-formList v-if="visible" :fromList="newFromList" ref="formListRef" @cancel="cancel" @getVal="getVal"></ly-formList>
    </ly-modal>
    <ly-modal v-model:visible="delVisible" title="系统提示" @handleOk="handleOk" :isTips="true" :loading="delLoading">
      <p>确定要删除这条数据吗?</p>
    </ly-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { PlusOutlined } from "@ant-design/icons-vue";
import { iptData, columns, fromList } from './organzition.js'
import { page, orgSave, orgDelete, orgList } from '@/api/system/organzition'
import { message } from 'ant-design-vue';
const visible = ref(false)
const delVisible = ref(false)
const editID = ref({})
const loading = ref(false)
const searchMsg = ref({})
const title = ref('')
const data = ref([])
const newFromList = ref([])
const tableRef = ref(null)
const expandId = ref('')
const leavel = ref('')
const formListRef = ref(null)
const delLoading = ref(false)
const pagination = ref({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total, range) => `共 ${total} 条`
})
//分页事件
const pageChange = (e) => {
  pagination.value = e
  reqPage()
}

//获取二级
const reqGetList = (e) => {
  reqGetLevel(e.id)
}
const reqGetLevel = (id) => {
  page({
    level: 2,
    parentOrgId: id
  }).then(res => {
    if (res.code !== 10000) return
    expandId.value = id
    data.value.forEach(item => {
      if (item.id === id) {
        item.children = res.data.records
      }
    })
  })
}
//删除事件
const handleOk = () => {
  delLoading.value = true
  orgDelete(editID.value.id).then(res => {
    if (res.code !== 10000) return
    message.success('操作成功')
    delVisible.value = false;
    if(leavel.value === 2){
        reqGetLevel(expandId.value)
        return
      }
    if (data.value.length === 1 && pagination.value.current > 1) {
      pagination.value.current--
    }
    reqPage()
  }).finally(() => {
    delLoading.value = false
  })
}
const delAdd = (item) => {
  leavel.value = item.children ? 1 : 2
  editID.value = item
  delVisible.value = true
}
// 编辑按钮
const edit = (item) => {
  leavel.value = item.children ? 1 : 2
  editID.value = item
  fromList.value[0].value = item.name
  title.value = '编辑'
  newFromList.value = fromList.value.slice(0, 1)
  visible.value = true;
}
//重置
const resetBtn = () => {
  pagination.value.current = 1
  searchMsg.value = {}
  reqPage()
}
// 搜索事件
const searchAdd = (e) => {
  pagination.value.current = 1
  searchMsg.value = e
  reqPage()
}
//添加机构
const newAdd = () => {
  fromList.value[0].value = ''
  editID.value = {}
  title.value = '添加机构'
  newFromList.value = fromList.value
  visible.value = true;
}
//获取表单的值
const getVal = (e) => {
  formListRef.value.fileLoading = true
  if (editID.value.id) {
    //编辑
    orgSave({
      name: e.name,
      id: editID.value.id,
      carrierId: editID.value.carrierId
    }).then((res) => {
      if (res.code !== 10000) return
      message.success('编辑成功')
      cancel()
      if(leavel.value === 2){
        reqGetLevel(expandId.value)
        return
      }
      tableRef.value.expandedRowKeys = []
      reqPage()
   
    }).finally(() => {
      if(formListRef.value){
      formListRef.value.fileLoading = false
     }
    });
  } else {
    // 新增
    orgSave({
      name: e.name,
      parentOrgId: e.parentOrgId
    }).then((res) => {
      if (res.code !== 10000) return
      message.success('新增成功')
      reqPage()
      cancel()
    }).finally(() => {
     if(formListRef.value){
      formListRef.value.fileLoading = false
     }  
    });
  }
}
const cancel = () => {
  visible.value = false;
}
const reqPage = () => {
  loading.value = true
  let msg = {
    current: pagination.value.current,
    size: pagination.value.pageSize,
    level: 1
  }
  Object.assign(msg, searchMsg.value)
  page(msg).then(res => {
    if (res.code !== 10000) return
    console.log(res);
    data.value = res.data.records;
    data.value.forEach(item => {
      item.children = []
    })
    pagination.value.total = res.data.total
    tableRef.value.expandedRowKeys = [] 
    reqPageMain()
  }).finally(() => {
    loading.value = false;
  })
}
const reqPageMain = () => {
  orgList({
    level: 1
  }).then(res => {
    if (res.code !== 10000) return
    fromList.value[1].opt = res.data.map(item => {
      return {
        label: item.name,
        value: item.id
      }
    })
  })
}
onMounted(() => {
  reqPage()
})
</script>

<style lang="less" scoped>
.tableBox {
  padding: 15px;
  background: #ffffff;
  height: calc(100vh - 230px);
}
</style>